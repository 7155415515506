import axios from "axios";
import { ICallConnection } from "frontend/canvas-designer-new/video-calls/calls-interface";
import {
  CloseTracksResponse,
  NewSessionResponse,
  SessionDescription,
  TrackObject,
  TracksResponse,
} from "frontend/canvas-designer-new/video-calls/calls-types";
import { Plan } from "shared/consts";
import { BillingInterval, CreateSetupIntentBody, StripePortalConfig } from "shared/datamodel/schemas/billing";
import {
  CanvasExampleTemplates,
  canvasTemplateAdminSchema,
  CanvasTemplateForm,
  CanvasUpdateTemplateForm,
  canvasUpdateTemplateMinimalForm,
  mapTemplateFromDB,
  mapTemplateTagFromDB,
} from "shared/datamodel/schemas/canvas-template";
import {
  CreateTemplateLinkForm,
  mapTemplateLinkFromDB,
  UpdateTemplateLinkForm,
} from "shared/datamodel/schemas/template-link";
import { SignupFormValues } from "shared/datamodel/schemas/signup-form";
import { Board, boardFromDBModel, BoardPermission, BoardState } from "shared/datamodel/schemas/board";
import {
  Team,
  UserTeamPermission,
  PendingUserPermission,
  pendingUserPermissionFromDBModal,
  teamFromDBModel,
  TeamWithProjects,
} from "shared/datamodel/schemas/team";
import { Project, UserProjectPermission, projectFromDBModel } from "shared/datamodel/schemas/project";
import { MondayUser, Permission, User, userFromDBModel } from "shared/datamodel/schemas/user";
import { IntegrationType } from "shared/integrations/integration";
import { CustomerData } from "shared/datamodel/schemas/customerSupport";
import {
  BoardMondayInfo,
  boardMondayInfoFromDBModel,
  BoardMondayInfoType,
} from "shared/datamodel/schemas/board-monday-info";

// MARK: Setup
const baseURL = `${process.env.HOST!}`;
const headers: { [key: string]: string } = {
  "Content-Type": "application/json",
  Allow: "application/json",
};

const client = axios.create({
  baseURL: baseURL + `${process.env.NEXT_BRANCH_PATH}`,
  headers: headers,
  withCredentials: true,
  maxContentLength: 52428890,
  maxBodyLength: 52428890,
});

client.interceptors.response.use(
  function (response) {
    client.defaults.headers.common["x-csrf-token"] = response.headers["x-csrf-token"];
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export function getCSRFToken() {
  return client.defaults.headers.common["x-csrf-token"];
}

// MARK: Session management
export async function authenticateWithAppsCode(code: any) {
  return client
    .get("auth", {
      params: {
        code,
      },
    })
    .then((res) => res.data.user as MondayUser & { blocked: boolean; accountName: string });
}

export async function getAccountsCount(email: any) {
  return client
    .get("auth/accounts_count", {
      params: {
        email,
      },
    })
    .then((res) => res.data.accountsCount)
    .catch(() => 0);
}

export async function authIntegration(type: IntegrationType, data: any) {
  return client.post(`/integrations`, { type, data }).then((res) => res.data);
}

export async function logout() {
  return client.delete("auth");
}

export async function checkAuthStatus(): Promise<boolean> {
  return client.get("auth/check").then((res: any) => res.data.authenticated);
}

export async function getUser() {
  return client
    .get("users/me")
    .then((res: any) => res.data)
    .then(userFromDBModel);
}

export async function getSubscribedSeats({
  applyRestrictedLimitation,
  isPerSeatLicenseEnabled,
}: {
  applyRestrictedLimitation: boolean;
  isPerSeatLicenseEnabled: boolean;
}) {
  if (applyRestrictedLimitation && isPerSeatLicenseEnabled) {
    return client.get("users/count/subscribed").then((res: any) => {
      return res.data;
    });
  }
  return await getUsersCount();
}

export async function getAllUsers(): Promise<User[]> {
  return client
    .get("users/all")
    .then((res: any) => res.data)
    .then((users) => users.map(userFromDBModel));
}

export async function getUsersByPage(page: number): Promise<User[]> {
  return client
    .get(`users/page/${page}`)
    .then((res: any) => res.data)
    .then((users) => users.map(userFromDBModel))
    .catch(() => []);
}

export async function getUsersByIds(ids: string[]): Promise<User[]> {
  return client
    .get(`users/ids`, { params: { ids: ids.join(",") } })
    .then((res: any) => res.data)
    .then((users) => users.map(userFromDBModel));
}

export async function getUsersCount(): Promise<number> {
  return client.get(`users/count`).then((res: any) => res.data);
}

export async function getUsersForIds(ids: number[]) {
  return client
    .get("users", { params: { ids } })
    .then((res: any) => res.data)
    .then((users) => users.map(userFromDBModel) as (User | null)[]);
}

export async function inviteUser({
  usersPermissions,
  documentId,
  isPerSeatLicenseEnabled,
}: {
  usersPermissions: Record<string, Permission>;
  documentId?: string;
  isPerSeatLicenseEnabled: boolean;
}): Promise<{ users: any[]; failed: string[]; addedSeats: number }> {
  return client.post("users/invite", { usersPermissions, documentId, isPerSeatLicenseEnabled }).then((res: any) => {
    const users = res.data.invitedUsers.map(userFromDBModel);
    const failed = res.data.failedInvitedUsers;
    return { users, failed, addedSeats: parseInt(res.data.addedSeats) };
  });
}

export async function getPendingUsersPermissions(): Promise<PendingUserPermission[]> {
  return client
    .get("users/pending/permissions")
    .then((res: any) => res.data)
    .then((users) => users.map(pendingUserPermissionFromDBModal));
}

export async function askToEdit(): Promise<void> {
  return client.post("users/ask_to_edit");
}

// MARK: Boards
export async function getBoards({
  excludeMondayBoards = true,
  isPrivateByCreator = false,
}: { excludeMondayBoards?: boolean; isPrivateByCreator?: boolean } = {}): Promise<Board[]> {
  return client
    .get(`boards`, {
      params: {
        e: excludeMondayBoards,
        ...(isPrivateByCreator && { p: isPrivateByCreator }),
      },
    })
    .then((response: any) => response.data.boards.map(boardFromDBModel));
}

export async function deleteBoard(documentId: any): Promise<void> {
  return client.delete(`boards/${documentId}`);
}

export async function editBoard(board: Board): Promise<void> {
  return client.put(`boards/${board.documentId}`, board);
}

export async function moveBoard(board: Board): Promise<void> {
  return client.put(`boards/move/${board.documentId}`, board);
}

export async function markBoardAsSetup(board: Board): Promise<void> {
  return client.put("boards/state", {
    documentId: board.documentId,
    state: BoardState.default,
  });
}

export async function createBoard(
  name: string,
  templateIdOrAlias: string | null,
  state: BoardState = BoardState.default,
  projectId: number | null,
  teamId: number | null,
  boardMondayInfoId: number | null = null,
  permission: BoardPermission = BoardPermission.private
): Promise<Board> {
  console.log("creating board", { name, permission, state, createdFromTemplateId: templateIdOrAlias });
  return client
    .post("/boards", {
      name,
      permission,
      state,
      createdFromTemplateId: templateIdOrAlias,
      projectId,
      teamId,
      boardMondayInfoId,
    })
    .then((response) => boardFromDBModel(response.data));
}

export async function duplicateBoard(documentId: string): Promise<{ board: Board; contents: any }> {
  return client.post(`boards/duplicate/${documentId}`).then((res) => ({
    board: boardFromDBModel(res.data.board),
    contents: res.data.contents,
  }));
}

export async function fetchBoard(
  documentId: any,
  isPrivateByCreator: boolean = false
): Promise<{ board: Board; templateElements: Record<string, any> | null }> {
  if (isPrivateByCreator) {
    return client.get(`/boards/p/${documentId}`).then((response) => ({
      board: boardFromDBModel(response.data.board),
      templateElements: response.data.templateElements,
    }));
  }
  return client.get(`/boards/${documentId}`).then((response) => ({
    board: boardFromDBModel(response.data.board),
    templateElements: response.data.templateElements,
  }));
}

export async function getPasswordProtectedBoard(
  documentId: string,
  password: string
): Promise<{ board: Board; templateElements: any; authToken: string }> {
  return client.post(`/boards/password-protected/${documentId}`, { password }).then((res) => ({
    board: boardFromDBModel(res.data.board),
    templateElements: res.data.templateElements,
    authToken: res.data.authToken,
  }));
}

//#region board_monday_info

export async function createBoardMondayInfo(
  type: BoardMondayInfoType,
  instanceId: number | null,
  workspaceId: number | null,
  mondayURL: string,
  boardId?: number | null,
  boardViewId?: number | null
): Promise<BoardMondayInfo> {
  return client
    .post(`/boards-monday-info`, { type, instanceId, workspaceId, mondayURL, boardId, boardViewId })
    .then((response: any) => boardMondayInfoFromDBModel(response.data));
}

export async function fetchBoardMondayInfo(id: number): Promise<BoardMondayInfo> {
  return client.get(`/boards-monday-info/${id}`).then((response: any) => boardMondayInfoFromDBModel(response.data));
}

export async function getBoardMondayInfoByMondayURL(mondayURL: string): Promise<BoardMondayInfo> {
  const encodedURL = encodeURIComponent(mondayURL);
  return client
    .get(`/boards-monday-info?mondayURL=${encodedURL}`)
    .then((response: any) => boardMondayInfoFromDBModel(response.data));
}

//#endregion board_monday_info

export async function getRepsToken(documentId: string): Promise<string> {
  return client.get(`/reps/${documentId}/token`).then((res) => res.data.token);
}

export async function getDevRepsToken(documentId: string): Promise<string> {
  return client.get(`/reps/${documentId}/dev-token`).then((res) => res.data.token);
}

export async function getMaxCollaboratorsByCanvasId(documentId: string) {
  return client.get(`/boards/maxCollaborators/${documentId}`).then((res: any) => res.data.maxCollaborators);
}

export async function createPortalSession(portalConfig: StripePortalConfig) {
  return client.post(`/billing/customer-portal`, { portalConfig }).then((res: any) => res.data.url);
}

export async function updateSubscriptionSeats(seatsCount: number) {
  return client.put(`/billing/update-subscription-seats`, { seatsCount });
}

export async function createCheckoutSession(planId: Plan) {
  return client.post(`/billing/create-checkout-session`, { planId }).then((res: any) => res.data.url);
}

export async function createFreePlan() {
  return client
    .post("/billing/create-canvas-plan", { planId: Plan.basic, isTrial: false })
    .then((res: any) => res.data)
    .then(userFromDBModel);
}

export async function createProTrial() {
  return client
    .post("/billing/create-canvas-plan", { planId: Plan.pro, isTrial: true })
    .then((res: any) => res.data)
    .then(userFromDBModel);
}

export async function downgradeToBasic() {
  return client
    .post("/billing/downgrade-plan")
    .then((res: any) => res.data)
    .then(userFromDBModel);
}

export async function downgradeTouchAccountToBasic() {
  return client.post("/billing/downgrade-plan/touch-account").then((res: any) => res.data);
}

export async function getSignedUrl({
  fileName,
  fileType,
  action,
  fileId,
  documentId,
}: {
  fileName?: string;
  fileType?: string;
  fileId?: number;
  documentId?: string;
  action: "read" | "write";
}) {
  switch (action) {
    case "write": {
      return client.post("/files/gcs-sign-upload", { fileName, fileType }).then((res: any) => res.data);
    }
    case "read": {
      return client.post("/files/gcs-sign-read", { fileId, documentId }).then((res: any) => res.data);
    }

    default: {
      break;
    }
  }
}

export async function getWriteSignedUrl(url: string, fileType: string, uploadType = "element") {
  return client.post("/files/gcs-sign-upload2", { url, fileType, uploadType }).then((res) => res.data);
}

export async function getReadSignedUrl(url: string) {
  return client.post("/files/gcs-sign-read2", { url }).then((res) => res.data);
}

export async function copyFiles(copyRecord: Record<string, string>) {
  return client.post("/files/copy", { copyRecord }).then((res) => res.data);
}

export async function getUploadLimits(documentId: string): Promise<any> {
  return client.post("/files/get-upload-limits", { documentId }).then((response) => response.data);
}

export async function getPromotionInfo(promotionId: string): Promise<any> {
  return client.get(`/promotions/id/${promotionId}`).then((res) => res.data);
}

export async function getInAppPromotions(): Promise<any> {
  return client.get(`/promotions/in-app/all`).then((res) => res.data);
}

export async function triggerAction(action: string): Promise<void> {
  return client.post(`/trigger/${action}`);
}

export async function createPaymentIntent(newPlan: Plan, seatsCount: number, billingInterval: BillingInterval) {
  return client.post(`/billing/payment-intent`, { newPlan, seatsCount, billingInterval }).then((res) => res.data);
}

export async function createSetupIntent(createSetupIntentBody: CreateSetupIntentBody) {
  return client.post(`/billing/setup-intent`, createSetupIntentBody).then((res) => res.data);
}

export async function getSignupCsrfToken(): Promise<void> {
  return client.get(`/auth/signup`).then((res) => (client.defaults.headers.post["x-csrf-token"] = res.data.token));
}

export async function validateInvitation(email: string, token: string): Promise<void> {
  const params = new URLSearchParams({ email, token });
  return client
    .get(`/auth/validate_invitation`, { params })
    .then((res) => (client.defaults.headers.post["x-csrf-token"] = res.data.token));
}

export async function signup(formValues: SignupFormValues) {
  return client.post("/auth/signup", formValues).then((res) => res.data.code);
}

export async function getTemplates() {
  return client
    .get("/templates")
    .then((res) => res.data.templates)
    .then((templates: any[]) => templates.map((t) => mapTemplateFromDB(t)));
}

export async function getTemplatesAdmin() {
  return client
    .get("/templates/all")
    .then((res) => res.data.templates)
    .then((templates: any[]) => templates.map((t) => mapTemplateFromDB(t, canvasTemplateAdminSchema)));
}

export async function getTemplateData(templateId: string) {
  return client.get(`/templates/${templateId}/data`).then((res) => res.data);
}

export async function getTemplatesTags() {
  return client.get("/templates/tags").then((res) => (res.data as any[]).map(mapTemplateTagFromDB));
}

export async function createCanvasTemplate(template: CanvasTemplateForm) {
  return client.post("/templates", template).then((res) => mapTemplateFromDB(res.data.newTemplate));
}

export async function editCanvasTemplate(templateId: string, template: canvasUpdateTemplateMinimalForm) {
  return client.put(`/templates/${templateId}`, template);
}

export async function deleteCanvasTemplate(templateId: string) {
  return client.delete(`/templates/${templateId}`);
}

//this endpoint is used to update the template data from template admin only
export async function updateCanvasTemplate(token: string, template: CanvasUpdateTemplateForm) {
  return client.put(`/templates/admin/${template.id}`, template, { headers: { Authorization: token } });
}

export async function updateExmapeTemplatesOrderedByPreviewOrder(exampleTemplate: CanvasExampleTemplates) {
  return client.post(`/templates/updateExmapleTemplates`, exampleTemplate);
}

export async function createTemplateLink(template: CreateTemplateLinkForm) {
  return client.post("/templates/templateLinks", template).then((res) => mapTemplateLinkFromDB(res.data));
}

export async function updateTemplateLink(template: UpdateTemplateLinkForm) {
  return client
    .put(`/templates/templateLinks/${template.linkId}`, template)
    .then((res) => mapTemplateLinkFromDB(res.data));
}

export async function getTemplateLink({ id, type }: { id: string; type: string }) {
  return client.get(`/templates/templateLinks/${id}?type=${type}`).then((res) => mapTemplateLinkFromDB(res.data));
}

export async function deactivateTemplateLink(linkId: string) {
  return client.delete(`/templates/templateLinks/${linkId}`);
}

export async function getCordClientAuthToken(documentId?: string) {
  return client.post("/cord", { documentId }).then((res: any) => res.data);
}

export async function markTipSeen(name: string) {
  return client.post("/tips/mark_seen", { tip: name }).then((res) => res.data);
}

export async function markTipUnseen(name: string) {
  return client.post("/tips/mark_unseen", { tip: name }).then((res) => res.data);
}

export async function clearAllTips() {
  return client.post("/tips/clear_seen_tips").then((res) => res.data);
}

export async function markAnnouncementSeen(id: string) {
  return client.post("/announcements/mark_seen", { id }).then((res) => res.data);
}

export async function getPlansData() {
  return client.get("billing/plans").then((res) => res.data);
}

export async function checkCustomerPaymentMethod() {
  return client.get("billing/has-payment-method").then((res) => res.data);
}

export async function retrieveUpcomingInvoicePreview(
  newPlan: Plan,
  seatsCount: number,
  billingInterval: BillingInterval
) {
  return client
    .post("billing/retrieve-upcoming-invoice", {
      newPlan,
      seatsCount,
      billingInterval,
    })
    .then((res) => res.data);
}

export async function acceptInvitation({
  email,
  token,
  name,
  password,
}: {
  email: string;
  token: string;
  name: string;
  password: string;
}) {
  return client.post("auth/accept_invitation", { email, token, name, password }).then((res) => res.data.code);
}

// Integrations
export async function getBoardIntegrations(documentId: string) {
  return client.get(`/integrations/${documentId}`).then((res) => res.data);
}

export async function getIntegrationItems(
  documentId: string,
  integrations: { [integrationId: string]: any },
  optionalParams: {
    abortController?: AbortController;
    shouldExcludeRestrictedColumns?: boolean;
    mandatoryMappedColumnIds?: string[];
    shouldReduceLoadingTime?: boolean;
  } = {}
) {
  const { abortController, shouldExcludeRestrictedColumns, mandatoryMappedColumnIds, shouldReduceLoadingTime } =
    optionalParams;

  return client
    .post(
      `/integrations/board/${documentId}/items`,
      { integrations, mandatoryMappedColumnIds },
      {
        params: { shouldExcludeRestrictedColumns, shouldReduceLoadingTime },
        signal: abortController?.signal,
      }
    )
    .then((res) => res.data);
}

export async function createIntegration(type: IntegrationType, token: string) {
  return client.post("/integrations", { type, token }).then((res) => res.data);
}

export async function createBoardIntegration(documentId: string, integrationId: string, configuration: any) {
  return client.post(`/integrations/board`, { documentId, integrationId, configuration }).then((res) => res.data);
}

export async function updateBoardIntegration(integrationId: string, configuration: any) {
  return client.put(`/integrations/board/${integrationId}`, { configuration }).then((res) => res.data);
}

export async function getAccountIntegration() {
  return client.get(`/integrations/account`).then((res) => res.data);
}

export async function getIntegrationConfigData(
  integrationId: string,
  ids: string[] = [],
  optionalParams: {
    searchTerm?: string;
    shouldExcludeRestrictedColumns?: boolean;
  } = {}
) {
  const { searchTerm, shouldExcludeRestrictedColumns } = optionalParams;

  return client
    .get(`/integrations/${integrationId}/config`, { params: { ids, searchTerm, shouldExcludeRestrictedColumns } })
    .then((res) => res.data);
}

export async function createIntegrationItems(
  integrationId: string,
  titles: { [id: string]: string },
  groupId: string | undefined,
  columnValues: any,
  parentItemId?: string
) {
  return client
    .post(`/integrations/${integrationId}/create_items`, {
      itemNamesByIds: titles,
      groupId,
      columnValues,
      parentItemId,
    })
    .then((res) => res.data);
}

export async function updateIntegrationItem(integrationId: string, itemId: string, data: any) {
  return client.put(`/integrations/${integrationId}/item`, { itemId, data });
}

export async function getIntegrationItemIds({
  documentId,
  integrationId,
  filters,
  cursor,
  limit,
  includeAssetIds = false,
  includeGroupIds = false,
  shouldExcludeRestrictedColumns,
}: {
  documentId: string;
  integrationId: string;
  filters: any;
  cursor?: string;
  limit?: number;
  includeAssetIds?: boolean;
  includeGroupIds?: boolean;
  shouldExcludeRestrictedColumns?: boolean;
}) {
  return client
    .post(
      `/integrations/board/${documentId}/items_by_values`,
      {
        integrationId,
        filters,
        cursor,
        limit,
        includeAssetIds,
        includeGroupIds,
      },
      { params: { shouldExcludeRestrictedColumns } }
    )
    .then((res) => res.data);
}

export async function createMondaySolution(mondayIntegrationId: string, solutionId: string, creationId: string) {
  return client
    .post(`/integrations/create_monday_solution`, { integrationId: mondayIntegrationId, solutionId, creationId })
    .then((res) => res.data);
}

export async function getMondayBoardItems(
  integrationId: string,
  boardId: string,
  params: {
    query?: string;
    groupId?: string;
    itemIds?: string[];
    isSubitemsBoard?: boolean;
    requiredColumnIds?: string[];
  }
) {
  return client
    .get(`integrations/${integrationId}/monday_items_by_board/${boardId}`, { params })
    .then((res) => res.data);
}

export async function createTeam(name: string, users: number[] | null, permission: Permission): Promise<Team> {
  return client.post("/teams", { name, users, permission }).then((res) => teamFromDBModel(res.data));
}

// export async function createAccountTeam(name: string): Promise<Team> {
//   return client.post("/teams/account", { name }).then((res) => teamFromDBModel(res.data));
// }

export async function getTeam(teamId: string): Promise<Team> {
  return client.get(`teams/${teamId}`).then((res: any) => teamFromDBModel(res.data.team));
}

export async function getTeams(): Promise<Team[]> {
  return client.get("teams").then((res: any) => res.data.teams.map(teamFromDBModel));
}

export async function getTeamsWithProjects(): Promise<TeamWithProjects[]> {
  return client.get("teams/with-projects").then((res: any) => res.data);
}

export async function editTeam(
  documentId: string,
  payload: { name?: string; users?: UserTeamPermission[] | null; newOwner?: number }
): Promise<Team | void> {
  return client.put(`teams/${documentId}`, payload).then((res: any) => teamFromDBModel(res.data));
}

export async function deleteTeam(documentId: string): Promise<void> {
  return client.delete(`teams/${documentId}`);
}

export async function createProject(
  name: string,
  teamId?: number,
  users?: UserProjectPermission[] | null
): Promise<Project> {
  return client.post("/projects", { name, teamId, users }).then((res) => projectFromDBModel(res.data));
}

export async function getProjectsByIds(ids: string[]): Promise<Project[]> {
  return client
    .get(`projects/ids`, { params: { ids: ids.join(",") } })
    .then((res: any) => res.data)
    .then((projects) => projects.map(projectFromDBModel) as Project[]);
}

export async function deleteProject(documentId: string): Promise<void> {
  return client.delete(`projects/${documentId}`);
}

export async function editProject(
  project: Project,
  payload: { name?: string; users?: UserProjectPermission[] | null; newOwner?: number }
): Promise<Project> {
  return client.put(`projects/${project.documentId}`, payload).then((res: any) => projectFromDBModel(res.data));
}

// video calls
export function createCallsConnection(documentId: string, signal?: AbortSignal): ICallConnection {
  return {
    sessionId: "",

    sendRenegotiateAnswer(sdp: any): Promise<boolean> {
      return client
        .post("/video-calls/renegotiate/" + this.sessionId, { documentId, sdp }, { signal })
        .then((res) => res.status == 200);
    },

    newCallsSession(sessionDescription: SessionDescription) {
      if (this.sessionId != "") {
        console.warn("reconnecting to CF - overriding old session");
      }
      this.sessionId = "";
      return client
        .post<NewSessionResponse | null>("/video-calls/new-session", { documentId, sessionDescription }, { signal })
        .then((res) => {
          if (res.status == 201 && res.data?.sessionId) {
            this.sessionId = res.data.sessionId;
            return res.data;
          }
          return null;
        });
    },

    addTracksToSession(tracks: TrackObject[], sessionDescription?: SessionDescription) {
      const body = sessionDescription ? { documentId, tracks, sessionDescription } : { documentId, tracks };
      return client
        .post<TracksResponse>("/video-calls/add-tracks/" + this.sessionId, body, { signal })
        .then((res) => res.data);
    },

    closeTracks(sessionDescription: SessionDescription, tracks: { mid: string | undefined }[]) {
      return tracks.length == 0
        ? Promise.resolve<CloseTracksResponse>({})
        : client
            .put<CloseTracksResponse>("/video-calls/close-tracks/" + this.sessionId, {
              force: false,
              documentId,
              sessionDescription,
              tracks,
            })
            .then((res) => res.data);
    },
  };
}

export async function getStripeInfo(accountId: number): Promise<CustomerData> {
  return client.get(`/billing/customerSupport/${accountId}`).then((res: any) => res.data);
}

export async function downgradeToBasicFromCustomerPortal(accountId: string): Promise<User | null> {
  return client
    .post(`/billing/customerSupport/downgrade/${accountId}`)
    .then((res: any) => res.data)
    .then(userFromDBModel);
}

export async function processRefund(accountId: string, amountInCents: number, paymentIntent: string): Promise<void> {
  return client.post(`/billing/customerSupport/refund/${accountId}`, { amountInCents, paymentIntent });
}

export async function markInvoiceAsUncollectible(accountId: string, invoiceId: string): Promise<void> {
  return client.post(`/billing/customerSupport/uncollectible-invoice/${accountId}`, { invoiceId });
}

export async function getCustomerSupportPermission(): Promise<boolean> {
  return client.get(`/billing/customerSupport/permission`).then((res: any) => res);
}
