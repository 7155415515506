import { z } from "zod";

export enum BoardPermission {
  private = 0, // Only account users can access the board
  public = 1, // Anyone with the link can access the board
  publicReadOnly = 2, // Anyone with the link can access the board, but not edit it
}

export const PublicBoardPermissions = [BoardPermission.public, BoardPermission.publicReadOnly];
export function isPublicPermission(permission: BoardPermission) {
  return PublicBoardPermissions.includes(permission);
}

export const BoardPermissionMainTitles = {
  [BoardPermission.private]: "Only your team can access",
  [BoardPermission.public]: "Anyone with the link",
  [BoardPermission.publicReadOnly]: "Anyone with the link",
};

export const BoardPermissionAccessTitles = {
  [BoardPermission.private]: "",
  [BoardPermission.public]: "Can Edit",
  [BoardPermission.publicReadOnly]: "Can View",
};

export const BoardPermissionFullTitles = {
  [BoardPermission.private]: "Only your team can access",
  [BoardPermission.public]: "Anyone with the link can edit",
  [BoardPermission.publicReadOnly]: "Anyone with the link can view",
};

export enum BoardState {
  templateNotSetUp,
  default = 1,
  gettingStarted = 2,
}

export function fallback<T>(schema: z.ZodSchema<T>, value: T) {
  return z.any().transform((val) => {
    const safe = schema.safeParse(val);
    return safe.success ? safe.data : value;
  });
}

export type BoardDBSchema = {
  id: number;
  name: string;
  thumbnail: string | null;
  document_id: string;
  updated_at: string;
  permission: BoardPermission;
  account_id: number;
  is_owner: boolean;
  is_read_only: boolean | null;
  project_id: number | null;
  team_id: number | null;
  has_member_access: boolean;
  state: BoardState;
  created_from_template_id: string | null;
  is_downgraded: boolean;
  team_name: string | null;
  project_name: string | null;
  is_password_protected: boolean;
  password_hash: string | null;
  password_last_change: string | null;
  board_monday_info_id: number | null;
  deleted: boolean;
  created_by_user_id: boolean;
  created_at: string;
  created_reflect_room: boolean;
};

export const BOARD_PUBLIC_FIELDS = [
  "id",
  "name",
  "document_id",
  "updated_at",
  "created_at",
  "thumbnail",
  "permission",
  "account_id",
  "created_reflect_room",
  "is_password_protected",
  "password_last_change",
  "board_monday_info_id",
  "state",
] as Array<keyof BoardDBSchema>;

export type PublicBoardDBSchema = Pick<BoardDBSchema, typeof BOARD_PUBLIC_FIELDS[number]>;

export const BOARD_PRIVATE_FIELDS = [
  ...BOARD_PUBLIC_FIELDS,
  "created_from_template_id",
  "project_id",
  "team_id",
  "deleted",
  "is_read_only",
] as Array<keyof BoardDBSchema>;

export type PrivateBoardDBSchema = Pick<BoardDBSchema, typeof BOARD_PRIVATE_FIELDS[number]>;

export const boardSchema = z.object({
  id: z.number(),
  name: z.string(),
  thumbnail: z.string().nullable(),
  documentId: z.string(),
  updatedAt: z.date(),
  permission: fallback(z.nativeEnum(BoardPermission), BoardPermission.private),
  accountId: z.string(),
  isOwner: z.boolean().default(false),
  isReadOnly: z.boolean().default(false).nullable(),
  projectId: z.number().nullable().default(null),
  teamId: z.number().nullable().default(null),
  hasMemberAccess: z.boolean().default(false),
  state: fallback(z.nativeEnum(BoardState), BoardState.templateNotSetUp),
  createdFromTemplateId: z.string().nullish(),
  isDowngraded: z.boolean().default(false).optional(),
  teamName: z.string().nullish(),
  projectName: z.string().nullish(),
  isPasswordProtected: z.boolean().default(false),
  boardMondayInfoId: z.number().nullable(),
});

export type Board = z.infer<typeof boardSchema>;

export function boardFromDBModel(obj: any): Board {
  return boardSchema.parse({
    id: obj.id,
    name: obj.name,
    thumbnail: obj.thumbnail,
    documentId: obj.document_id,
    updatedAt: new Date(obj.updated_at),
    permission: obj.permission,
    accountId: obj.account_id.toString(),
    isOwner: obj.is_owner,
    isReadOnly: obj.is_read_only,
    projectId: obj.project_id,
    teamId: obj.team_id,
    hasMemberAccess: obj.has_member_access,
    state: obj.state,
    createdFromTemplateId: obj.created_from_template_id,
    isDowngraded: obj.is_downgraded,
    teamName: obj.team_name,
    projectName: obj.project_name,
    isPasswordProtected: obj.is_password_protected,
    boardMondayInfoId: obj.board_monday_info_id,
  });
}

export const boardsResponseSchema = z.object({
  boards: z.array(boardSchema),
});

export type BoardsResponse = z.infer<typeof boardsResponseSchema>;
